import router from "./router";

router.beforeEach(async (to, from, next) => {
  // console.log(to, from, next, "路由拦截");
  //需要登录权限进入的路由
  if (localStorage.getItem("isLogin") == "true") {
    //得到登录信息，进行下一步
    if (to.name === "Login") {
      console.log("已登录");
      router.push({ path: "/" });
    }
    next();
  } else {
    if (to.name != "Login"){
        router.push({ path: "/Login" }).catch(() => {});
    }
    next();
  }
});
