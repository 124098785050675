import Vue from 'vue'
import App from './App.vue'
import Element from 'element-ui'
// import '@/assets/styles/index.scss' // global css
// import '@/assets/styles/ruoyi.scss' // ruoyi css
import 'element-ui/lib/theme-chalk/index.css';
import './assets/icons' // icon
import axios from 'axios'
import router from './router'
import store from './store'
import Tmap from '@map-component/vue-tmap'
import './permission' // 路由权限
import 'lib-flexible/flexible'
// import echarts from 'echarts'
import * as echarts from 'echarts'

Vue.use(Element);

Vue.config.productionTip = false

Vue.prototype.$http= axios

Vue.prototype.$echarts = echarts

new Vue({
  router,
  store,
  axios,
  Tmap,
  render: h => h(App)
}).$mount('#app')
